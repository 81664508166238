import type { HeroContent } from '@hero/types'

export const HERO_DATA_KEY = Symbol() as InjectionKey<{
    heroData: Ref<HeroContent | undefined>
    setHeroData(pageSlug: string, skeleton?: string): Promise<void>
}>

export const useHero = () => {
    const hero = inject(HERO_DATA_KEY)

    if (!hero) throw new Error('Hero not provided!')

    return hero
}
